import React, { useState, useEffect } from "react"
import axios from "axios"
import { Button, Box, Typography, TextField, CircularProgress, Backdrop, Grid, Snackbar, Alert } from "@mui/material"
import { MuiTelInput } from "mui-tel-input"
import { FaYandex, FaGoogle, FaVk, FaTripadvisor, FaWhatsapp, FaMoon, FaStore, FaExternalLinkAlt } from "react-icons/fa"
import { FaUserDoctor, FaStar } from "react-icons/fa6"
import { BsPinMapFill, BsPersonLinesFill } from "react-icons/bs"
import { MdOutlineRateReview } from "react-icons/md"
import { TbSquareLetterY } from "react-icons/tb"
import TelegramIcon from "@mui/icons-material/Telegram"
import smile from "../images/smile.png"
import angry from "../images/angry.png"
import { useNavigate } from "react-router-dom"
import "./TemplatePage.css"

const TemplatePage = ({ id }) => {
	const navigate = useNavigate()
	const [feedback, setFeedback] = useState(null)
	const [phoneNumber, setPhoneNumber] = useState("")
	const [message, setMessage] = useState("")
	const [snackbarOpen, setSnackbarOpen] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState("")
	const [snackbarSeverity, setSnackbarSeverity] = useState("info")
	const [data, setData] = useState()
	let services = []

	function getCSRFToken() {
		const csrfCookie = document.cookie.match(/csrftoken=([\w-]+)/)
		return csrfCookie ? csrfCookie[1] : null
	}

	useEffect(() => {
		axios
			.get(`/api/pages/get_page/?id=${id}`)
			.then(response => {
				setData(response.data.page) // Предполагается, что response.data - это массив страниц
			})
			.catch(error => {
				console.error("Ошибка при загрузке страниц:", error)
			})
	}, [])

	const handleFeedback = type => {
		setFeedback(type)
	}

	const handleSnackbarClose = () => {
		setSnackbarOpen(false)
	}

	const handleSubmit = () => {
		// Проверяем, что поля заполнены
		if (!message.trim() || !phoneNumber.trim()) {
			setSnackbarMessage("Не все поля заполнены")
			setSnackbarSeverity("warning")
			setSnackbarOpen(true)
			return
		}

		// Если поля заполнены, отправляем POST запрос
		const postData = {
			user_id: data.organization.user.user_id,
			message: message,
			phone_number: phoneNumber, // Исправьте на правильные имена переменных в вашем контексте
			// Добавьте другие данные по необходимости
		}

		axios
			.post("/api/users/send_review/", postData, {
				headers: {
					"X-CSRFToken": getCSRFToken(),
				},
			})
			.then(response => {
				// Обработка успешного запроса
				setMessage("")
				setPhoneNumber("")
				setSnackbarMessage("Сообщение отправлено успешно")
				setSnackbarSeverity("success")
				setSnackbarOpen(true)
				navigate("/success")
			})
			.catch(error => {
				// Обработка ошибки в запросе
				setSnackbarMessage("Что-то пошло не так!")
				setSnackbarSeverity("error")
				setSnackbarOpen(true)
			})
	}

	const handleChangePhoneNumber = newValue => {
		setPhoneNumber(newValue)
	}

	if (data) {
		services = [
			{ name: "Наш сайт", link: data.organization.site_link, icon: <FaExternalLinkAlt />, color: "#4596e6" },
			{ name: "Онлайн-запись", link: data.organization.recording, icon: <BsPersonLinesFill />, color: "#5aad8d" },
			{ name: "Яндекс Карты", link: data.organization.yandex_maps_link, icon: <FaYandex />, color: "#EF9A9A" },
			{ name: "2GIS", link: data.organization.twogis_link, icon: <BsPinMapFill />, color: "#9575CD" },
			{ name: "Google Maps", link: data.organization.google_maps_link, icon: <FaGoogle />, color: "#64B5F6" },
			{ name: "VK", link: data.organization.vk_link, icon: <FaVk />, color: "#7986CB" },
			{ name: "Prodoctorov", link: data.organization.prodoctorov_link, icon: <FaUserDoctor />, color: "#81C784" },
			{ name: "iRecommend", link: data.organization.ireccomend_link, icon: <FaStar />, color: "#F06292" },
			{ name: "Zoon", link: data.organization.zoon_link, icon: <FaMoon />, color: "#FFD54F" },
			{ name: "Otzovik", link: data.organization.otzovik_link, icon: <MdOutlineRateReview />, color: "#FFB74D" },
			{ name: "Yell", link: data.organization.yell_link, icon: <TbSquareLetterY />, color: "#DCE775" },
			{ name: "TripAdvisor", link: data.organization.tripadvisor_link, icon: <FaTripadvisor />, color: "#4DB6AC" },
			{ name: "WhatsApp", link: data.organization.whatsapp_link, icon: <FaWhatsapp />, color: "#9CCC65" },
			{ name: "Avito", link: data.organization.avito_link, icon: <FaStore />, color: "#FF8A65" },
		]
	}

	if (!data) {
		return (
			<Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
				<CircularProgress color="inherit" />
			</Backdrop>
		)
	}

	return (
		<Box
			className="template-page"
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				padding: "40px",
			}}
		>
			{feedback === null && (
				<React.Fragment>
					<img src={`data:image/jpeg;base64,${data.organization.logo}`} alt="Logo" style={{ maxHeight: "150px", maxWidth: "150px", marginBottom: "20px", marginTop: "20px" }} />
					<Typography variant="h6" sx={{ marginBottom: "20px", textAlign: "center" }} className="adaptive-color">
						Расскажите, понравилось ли Вам обслуживание в {data.organization.name}?
					</Typography>
					<Button variant="contained" color="success" onClick={() => handleFeedback("positive")} sx={{ width: "100%", marginBottom: "10px", backgroundColor: "#4CAF50", padding: "20px" }}>
						Да, всё отлично!
					</Button>
					<Button variant="contained" color="error" onClick={() => handleFeedback("negative")} sx={{ width: "100%", backgroundColor: "#F44336", padding: "20px" }}>
						Нет, есть претензии!
					</Button>
				</React.Fragment>
			)}
			{feedback === "positive" && (
				<Box sx={{ textAlign: "center" }}>
					<div style={{ color: "#F44336" }}>
						<img src={smile} alt="smile" style={{ maxHeight: "150px", marginBottom: "20px", marginTop: "20px" }} />
					</div>
					<Typography variant="h5" sx={{ fontWeight: "bold", margin: "10px 0", textAlign: "left" }} className="adaptive-color">
						Оставьте отзыв о нашей работе
					</Typography>
					<Typography sx={{ fontSize: 16, textAlign: "left", fontWeight: 300, marginBottom: "30px" }} className="adaptive-color">
						Обычно, люди не находят времени оставлять отзывы, а вы нашли, спасибо!
					</Typography>
					<Grid container spacing={2} sx={{ padding: 0 }}>
						{services
							.filter(service => service.link)
							.map(
								(
									service,
									index // Фильтруем услуги только с ссылками
								) => (
									<Grid item xs={12} sm={6} key={index} sx={{ padding: 0 }}>
										{" "}
										{/* xs=12 для мобильных и sm=6 для планшетов и выше */}
										<Button
											variant="outlined"
											fullWidth
											size="large"
											startIcon={service.icon}
											href={service.link}
											target="_blank"
											rel="noopener noreferrer"
											sx={{
												backgroundColor: "transparent",
												color: service.color,
												borderColor: service.color,
												height: "100%",
												padding: "20px", // Задаем отступы для кнопки
												"&:hover": {
													backgroundColor: service.color,
													color: "white",
													opacity: 0.9,
													borderColor: service.color,
												},
											}}
										>
											{service.name}
										</Button>
									</Grid>
								)
							)}
					</Grid>
				</Box>
			)}
			{feedback === "negative" && (
				<Box sx={{ textAlign: "center" }}>
					<div style={{ color: "#F44336" }}>
						<img src={angry} alt="smile" style={{ maxHeight: "150px", marginBottom: "20px", marginTop: "20px" }} />
					</div>
					<Typography variant="h4" sx={{ fontWeight: "bold", margin: "10px 0", fontWeight: 700 }} className="adaptive-color">
						Пожаловаться
					</Typography>
					<Typography variant="body1" sx={{ fontSize: 18, marginBottom: "20px", textAlign: "left", fontWeight: 300 }} className="adaptive-color">
						Расскажите, что Вам не понравилось, и что мы можем делать лучше. Будьте уверены, проблема будет решена!
					</Typography>
					<Typography variant="body1" sx={{ fontSize: 14, marginBottom: "5px", textAlign: "left", fontWeight: 300 }} className="adaptive-color">
						Ваш телефон:
					</Typography>
					<MuiTelInput className="main-phone-input" defaultCountry="RU" forceCallingCode focusOnSelectCountry langOfCountryName="ru" flagSize="small" variant="outlined" placeholder="Номер телефона" value={phoneNumber} onChange={handleChangePhoneNumber} />
					<Typography variant="body1" sx={{ fontSize: 14, marginBottom: "5px", textAlign: "left", fontWeight: 300 }} className="adaptive-color">
						Ваше сообщение:
					</Typography>
					<TextField fullWidth label="Ваше сообщение" multiline rows={4} value={message} onChange={e => setMessage(e.target.value)} sx={{ marginBottom: "20px" }} />
					<Button variant="outlined" color="primary" fullWidth onClick={handleSubmit} style={{ color: "black", borderColor: "#999", backgroundColor: "transparent", height: "50px", fontSize: "18px", textTransform: "none" }} startIcon={<TelegramIcon style={{ fontSize: 30, color: "#ff4081" }} />}>
						Отправить
					</Button>
					<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
						<Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
							{snackbarMessage}
						</Alert>
					</Snackbar>
				</Box>
			)}
		</Box>
	)
}

export default TemplatePage
