import React from "react"
import { Link } from "react-router-dom"
import "./NotFound.css"

const NotFoundPage = () => {
	return (
		<div className="not-found">
			<h1>404</h1>
			<div className="text">
				<p>Страница, которую вы ищете, не существует или была перемещена.</p>
				<Link className="link" to="/">
					Вернуться на главную
				</Link>
				<p>Если вы уверены, что страница должна существовать, и это ошибка, свяжитесь с администратором.</p>
				<Link className="link" to="/">
					Связаться с администратором
				</Link>
			</div>
		</div>
	)
}

export default NotFoundPage
