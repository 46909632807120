import React, { useState, useEffect } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import axios from "axios"

import MainPage from "./MainPage/MainPage"
import AdminLoginPage from "./AdminLoginPage/AdminLoginPage"
import AdminDashboardPage from "./AdminDashboardPage/AdminDashboardPage"
import NotFound from "./NotFound/NotFound"
import TemplatePage from "./TemplatePage/TemplatePage"
import ChangePassword from "./ChangePassword/ChangePassword"
import "./App.css"
import RecoveryPassword from "./RecoveryPassword/RecoveryPassword"
import StatisticPage from "./StatisticPage/StatisticPage"
import SuccessPage from "./SuccessPage/SuccessPage"

function App() {
	const [pages, setPages] = useState([])
	const [isLoading, setIsLoading] = useState(true) // Добавляем состояние для отслеживания загрузки

	useEffect(() => {
		// Загрузка данных о страницах
		axios
			.get("/api/pages/get_pages_short_info/")
			.then(response => {
				setPages(response.data.pages) // Предполагается, что response.data - это массив страниц
			})
			.catch(error => {
				console.error("Ошибка при загрузке страниц:", error)
			})
			.finally(() => {
				setIsLoading(false) // Устанавливаем состояние загрузки в false после завершения запроса
			})
	}, [])

	if (isLoading) {
		return <div></div> // Или компонент индикатора загрузки
	}

	return (
		<Router>
			<Routes>
				<Route path="/" element={<MainPage />} />
				<Route path="/admin-login/" element={<AdminLoginPage />} />
				<Route path="/admin-dashboard/" element={<AdminDashboardPage />} />
				<Route path="/admin-change-password/" element={<ChangePassword />} />
				<Route path="/admin-recovery-password/" element={<RecoveryPassword />} />
				<Route path="/admin-statistics/" element={<StatisticPage />} />
				<Route path="/success/" element={<SuccessPage />} />

				{pages.map(page => page.status === 1 && <Route key={page.id} path={`/${page.name}`} element={<TemplatePage id={page.id} />} />)}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	)
}

export default App
