import React from "react"
import "./RecoveryPassword.css"
import axios from "axios"
import { Alert, Button, Snackbar, TextField, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

const RecoveryPassword = () => {
	const [openSuccess, setOpenSuccess] = React.useState(false)
	const [openError, setOpenError] = React.useState(false)
	const [status, setStatus] = React.useState("")

	const schema = yup
		.object({
			email: yup
				.string()
				.matches(/[-A-Za-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-A-Za-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?/, "Формат адреса почты: ********@****.**")
				.required("Почта является обязательной"),
		})
		.required()

	const {
		register,
		handleSubmit,
		reset,
		getValues,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) })

	function getCSRFToken() {
		const csrfCookie = document.cookie.match(/csrftoken=([\w-]+)/)
		return csrfCookie ? csrfCookie[1] : null
	}

	const fetchChangePass = async () => {
		const response = await axios.post("/api/custom_users/recovery_password/", { email: getValues("email") }, { headers: { "X-CSRFToken": getCSRFToken() } })
		if (response.data.status === true) {
			setOpenSuccess(true)
			setStatus("Пароль успешно изменён")
			reset({ email: "", password: "", confirmPassword: "" })
		} else {
			setOpenError(true)
			setStatus("Почта введена неверно")
		}
	}

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return
		}
		setOpenError(false)
		setOpenSuccess(false)
	}

	const onSubmit = () => {
		fetchChangePass()
	}
	return (
		<div className="wrapper">
			<h1>Восстановить пароль</h1>
			<form onSubmit={handleSubmit(onSubmit)} id="change-pass-form">
				<TextField type="email" size="small" autoComplete="email" id="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" label="Введите e-mail" variant="outlined" {...register("email")} className="input" />
				<p className="error">{errors.email?.message}</p>
				<Typography variant="body2" color="error" align="center" style={{ marginBottom: "8px" }}>
					<Link to="/admin-login">Помните пароль?</Link>
				</Typography>
				<Button variant="contained" type="submit">
					Отправить новый пароль на почту
				</Button>
			</form>
			<Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success">
					{status}
				</Alert>
			</Snackbar>
			<Snackbar open={openError} autoHideDuration={3000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					{status}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default RecoveryPassword
