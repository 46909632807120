import React, { useState } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { TextField, Button, Typography, Container, Grid, IconButton, InputAdornment, Backdrop, CircularProgress } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import "./AdminLoginPage.css"

const AdminLoginPage = () => {
	const navigate = useNavigate()

	// Состояния для имени пользователя, пароля и сообщения об ошибке
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [error, setError] = useState("")
	const [showPassword, setShowPassword] = useState(false)

	const [loading, setLoading] = useState(false)

	function getCSRFToken() {
		const csrfCookie = document.cookie.match(/csrftoken=([\w-]+)/)
		return csrfCookie ? csrfCookie[1] : null
	}

	// Функция для обработки отправки формы
	const handleSubmit = async e => {
		e.preventDefault()
		setError("")
		setLoading(true)

		const data = {
			username: username,
			password: password,
		}

		axios
			.post("/api/custom_users/login/", data, {
				headers: {
					"X-CSRFToken": getCSRFToken(),
				},
			})
			.then(response => {
				if (response.data.status) {
					navigate("/admin-dashboard")
				} else {
					setError("Неверное имя пользователя или пароль")
				}
			})
			.catch(error => {
				setError("Произошла ошибка при подключении к серверу")
				console.error("Axios error:", error)
			})

		setLoading(false)
	}

	// Функция для переключения видимости пароля
	const handleShowPassword = () => {
		setShowPassword(!showPassword)
	}

	return (
		<Container maxWidth="xs" className="admin-login-page">
			<Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }} spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h4" align="center" gutterBottom>
						OTZO.PRO
					</Typography>
					<Typography variant="h5" align="center" gutterBottom>
						Вход для администратора
					</Typography>
					<form onSubmit={handleSubmit}>
						<TextField label="Имя пользователя" fullWidth variant="outlined" margin="normal" value={username} onChange={e => setUsername(e.target.value)} />
						<TextField
							label="Пароль"
							fullWidth
							variant="outlined"
							margin="normal"
							type={showPassword ? "text" : "password"}
							value={password}
							onChange={e => setPassword(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton aria-label="toggle password visibility" onClick={handleShowPassword} edge="end">
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						{error && (
							<Typography variant="body2" color="error" align="center" style={{ marginBottom: "8px" }}>
								{error}
							</Typography>
						)}

						<Typography variant="body2" color="error" align="center" style={{ marginBottom: "8px" }}>
							<Link to="/admin-recovery-password">Забыли пароль?</Link>
						</Typography>
						<Button type="submit" variant="contained" color="primary" fullWidth size="large">
							Войти
						</Button>
					</form>
				</Grid>
			</Grid>
		</Container>
	)
}

export default AdminLoginPage
