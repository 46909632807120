import React from "react"
import "./ChangePassword.css"
import axios from "axios"
import { Link } from "react-router-dom"
import { Alert, Button, Typography, Snackbar, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

const ChangePassword = () => {
	const [openSuccess, setOpenSuccess] = React.useState(false)
	const [openError, setOpenError] = React.useState(false)
	const [status, setStatus] = React.useState("")

	const schema = yup
		.object({
			email: yup
				.string()
				.matches(/[-A-Za-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-A-Za-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?/, "Формат адреса почты: ********@****.**")
				.required("Почта является обязательной"),
			password: yup
				.string()
				.matches(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/g, "В пароле должны быть: цифры, строчные буквы, заглавные буквы")
				.min(8, "Минимальная длина пароля 8 символов")
				.required("Пароль является обязательным"),
			confirmPassword: yup
				.string()
				.oneOf([yup.ref("password"), ""], "Пароли не совпадают")
				.min(8, "Минимальная длина пароля 8 символов")
				.required("Пароль является обязательным"),
		})
		.required()

	const {
		register,
		handleSubmit,
		reset,
		getValues,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) })

	const fetchChangePass = async () => {
		const csrfCookie = document.cookie.match(/csrftoken=([\w-]+)/)
		const response = await axios.post("/api/custom_users/change_password/", { email: getValues("email"), password: getValues("password") }, { headers: { "X-CSRFToken": csrfCookie } })
		if (response.data.status === true) {
			setOpenSuccess(true)
			setStatus("Пароль успешно изменён")
			reset({ email: "", password: "", confirmPassword: "" })
		} else {
			setOpenError(true)
			setStatus("Произошла ошибка")
		}
	}

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return
		}
		setOpenError(false)
		setOpenSuccess(false)
	}

	const onSubmit = () => {
		fetchChangePass()
	}
	return (
		<div className="wrapper">
			<h1>Изменить пароль</h1>
			<Typography variant="body2" color="error" align="center" style={{ marginBottom: "8px" }}>
				<Link to="/admin-dashboard">Вернуться в админ-панель</Link>
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)} id="change-pass-form">
				<TextField type="email" size="small" autoComplete="email" id="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" label="Введите e-mail" variant="outlined" {...register("email")} className="input" />
				<p className="error">{errors.email?.message}</p>
				<TextField type="password" size="small" autoComplete="new-password" id="password" label="Введите пароль" variant="outlined" {...register("password")} className="input" />
				<p className="error">{errors.password?.message}</p>
				<TextField type="password" size="small" autoComplete="new-password" id="confirmPassword" label="Повторите пароль" variant="outlined" {...register("confirmPassword")} className="input" />
				<p className="error">{errors.confirmPassword?.message}</p>
				<Button variant="contained" type="submit">
					Изменить
				</Button>
			</form>
			<Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success">
					{status}
				</Alert>
			</Snackbar>
			<Snackbar open={openError} autoHideDuration={3000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					{status}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default ChangePassword
