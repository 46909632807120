import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import QRCode from "qrcode.react"
import axios from "axios"
import "./AdminDashboardPage.css"
import { Snackbar, Alert, Box, AppBar, Toolbar, Typography, IconButton, Table, TableBody, TableSortLabel, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from "@mui/material"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import DeleteIcon from "@mui/icons-material/Delete"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import ToggleOffIcon from "@mui/icons-material/ToggleOff"
import InfoIcon from "@mui/icons-material/Info"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { ruRU } from "@mui/material/locale"
import { format, addDays, parseISO } from "date-fns"
import { useForm } from "react-hook-form"
import { Checkbox, FormControlLabel } from "@mui/material"

const headCells = [
	{
		id: "client",
		numeric: false,
		disablePadding: false,
		label: "Клиент",
	},
	{
		id: "telegram",
		numeric: false,
		disablePadding: false,
		label: "Telegram ID пользователя",
	},
	{
		id: "phone",
		numeric: false,
		disablePadding: false,
		label: "Номер телефона",
	},
	{
		id: "sub",
		numeric: false,
		disablePadding: false,
		label: "Подписка",
	},
	{
		id: "org",
		numeric: false,
		disablePadding: false,
		label: "Организация",
	},
	{
		id: "page",
		numeric: false,
		disablePadding: false,
		label: "Страница",
	},
]

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell key={99} align="right" padding="normal">
					Действия
				</TableCell>
			</TableRow>
		</TableHead>
	)
}

const AdminDashboardSecondPage = () => {
	const navigate = useNavigate()
	const [order, setOrder] = React.useState("asc")
	const [orderBy, setOrderBy] = React.useState("client")
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [filterText, setFilterText] = React.useState("")
	const [selected, setSelected] = React.useState([])

	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = useForm({ defaultValues: { search: "" } })

	const onSearch = () => {
		setFilterText(getValues("search"))
	}

	function getCSRFToken() {
		const csrfCookie = document.cookie.match(/csrftoken=([\w-]+)/)
		return csrfCookie ? csrfCookie[1] : null
	}

	// Определение светлой темы
	const theme = createTheme(
		{
			palette: {
				primary: { main: "#1976D2" }, // Нежно-голубой для AppBar
				background: { default: "#f5f5f5", paper: "#fff" }, // Светло-серый для фона и белый для Paper
				text: { primary: "#333", secondary: "#555" }, // Темные оттенки для текста
			},
			// Дополнительные настройки темы могут быть добавлены здесь
		},
		ruRU
	)

	// Данные для таблицы (замените на реальные данные)
	const [rows, setRows] = useState([])

	useEffect(() => {
		const loadPages = async () => {
			try {
				const response = await axios.get("/api/pages/get_pages/")
				setRows(response.data.pages)
			} catch (error) {
				console.error("Ошибка при получении данных:", error)
			}
		}

		loadPages() // Вызываем функцию при загрузке компонента
	}, [])

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelected = rows.map(n => n.title)
			setSelected(newSelected)
			return
		}
		setSelected([])
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const [openAddDialog, setOpenAddDialog] = useState(false)

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
	const [deletingRow, setDeletingRow] = useState(null)

	// Определяем начальную дату (+7 дней от текущей даты)
	const initialDate = addDays(new Date(), 7)

	// Функция для форматирования даты в нужный формат (дд.мм.гггг)
	const formatDate = date => {
		if (typeof date === "string") {
			// Если входная дата является строкой, попробуйте её конвертировать в объект Date
			date = new Date(date)
		}

		if (date instanceof Date && !isNaN(date)) {
			// Проверка, что date является действительным объектом Date
			const day = date.getDate()
			const month = date.getMonth() + 1 // Месяцы начинаются с 0
			const year = date.getFullYear()
			return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`
		} else {
			// Если не удалось конвертировать в Date или date недействителен, вернуть пустую строку или другое значение по умолчанию
			return "" // Или можно вернуть, например, 'Неверный формат даты'
		}
	}

	// Определяем состояние для выбранной даты
	const [selectedDate, setSelectedDate] = useState(initialDate)

	// Функция для обработки изменений в поле подписки
	const handleSubscriptionChange = newDate => {
		setSelectedDate(newDate) // Обновляем состояние с новой датой
	}

	const initialPageState = {
		organization: {
			user: {
				name: "",
				user_id: "",
				referrer_id: "",
				phone_number: "",
				subscription: formatDate(addDays(new Date(), 7)), // Используйте ISO строку для даты
			},
			trial_period: true,
			subscription_renewed: false,
			name: "",
			recording: "",
			site_link: "",
			yandex_maps_link: "",
			google_maps_link: "",
			twogis_link: "",
			vk_link: "",
			prodoctorov_link: "",
			ireccomend_link: "",
			zoon_link: "",
			otzovik_link: "",
			yell_link: "",
			tripadvisor_link: "",
			whatsapp_link: "",
			avito_link: "",
			logo: null,
		},
		name: "",
		status: 1,
	}

	// Состояния для полей формы добавления страницы
	const [newPage, setNewPage] = useState(initialPageState)

	// Функции для открытия и закрытия модального окна
	const handleOpenAddDialog = () => {
		setOpenAddDialog(true)
	}

	const handleCloseAddDialog = () => {
		setNewPage(initialPageState)
		setIsLogoUploaded(false)
		setOpenAddDialog(false)
	}

	// Функция для обновления состояний полей формы
	const handleNewPageChange = prop => event => {
		let updatedValue = event.target.value

		// Разбиение свойства на подсвойства (если оно вложенное)
		const props = prop.split(".")
		let updatedNewPage = { ...newPage }

		// Обновление вложенных свойств
		props.reduce((acc, p, i) => {
			if (i === props.length - 1) {
				acc[p] = updatedValue
			} else {
				if (!acc[p]) acc[p] = {} // Если вложенный объект не существует, создаем его
				return acc[p]
			}
		}, updatedNewPage)

		setNewPage(updatedNewPage)
	}

	const [isLogoUploaded, setIsLogoUploaded] = useState(false)

	const handleLogoChange = event => {
		let file = event.target.files[0]
		if (file) {
			const reader = new FileReader()
			reader.onloadend = () => {
				setNewPage(prevState => ({
					...prevState,
					organization: {
						...prevState.organization,
						logo: reader.result,
					},
				}))
				setIsLogoUploaded(true)
			}
			reader.readAsDataURL(file)
		} else {
			setNewPage(prevState => ({
				...prevState,
				organization: {
					...prevState.organization,
					logo: null,
				},
			}))
			setIsLogoUploaded(false)
		}
	}

	const [isEditedLogoUploaded, setIsEditedLogoUploaded] = useState(false)

	const handleEditedLogoChange = event => {
		let file = event.target.files[0]
		if (file) {
			const reader = new FileReader()
			reader.onloadend = () => {
				setEditedPage(prevState => ({
					...prevState,
					organization: {
						...prevState.organization,
						logo: reader.result,
					},
				}))
				setIsEditedLogoUploaded(true)
			}
			reader.readAsDataURL(file)
		} else {
			setEditedPage(prevState => ({
				...prevState,
				organization: {
					...prevState.organization,
					logo: null,
				},
			}))
			setIsEditedLogoUploaded(false)
		}
	}

	const transformDataForState = postData => {
		// Преобразуем дату подписки из ISO в формат DD.MM.YYYY
		const formattedSubscriptionDate = postData.user && postData.user.subscription ? format(parseISO(postData.user.subscription), "dd.MM.yyyy") : ""

		return {
			id: postData.page.id,
			organization: {
				...postData.organization,
				user: {
					...postData.user,
					// Используем отформатированную дату
					subscription: formattedSubscriptionDate,
				},
			},
			name: postData.page.name,
			status: postData.page.status,
		}
	}

	// Функция для добавления страницы
	const handleAddPage = () => {
		if (validatePage(newPage)) {
			const data = {
				user: {
					name: newPage.organization.user.name, // Обновлено
					user_id: newPage.organization.user.user_id, // Обновлено
					referrer_id: newPage.organization.user.referrer_id, // Обновлено
					phone_number: newPage.organization.user.phone_number, // Обновлено
					subscription: formatDate(selectedDate),
				},
				organization: {
					trial_period: newPage.organization.trial_period,
					subscription_renewed: newPage.organization.subscription_renewed,
					name: newPage.organization.name,
					recording: newPage.organization.recording,
					site_link: newPage.organization.site_link,
					logo: newPage.organization.logo,
					yandex_maps_link: newPage.organization.yandex_maps_link,
					google_maps_link: newPage.organization.google_maps_link,
					twogis_link: newPage.organization.twogis_link,
					vk_link: newPage.organization.vk_link,
					prodoctorov_link: newPage.organization.prodoctorov_link,
					ireccomend_link: newPage.organization.ireccomend_link,
					zoon_link: newPage.organization.zoon_link,
					otzovik_link: newPage.organization.otzovik_link,
					yell_link: newPage.organization.yell_link,
					tripadvisor_link: newPage.organization.tripadvisor_link,
					whatsapp_link: newPage.organization.whatsapp_link,
					avito_link: newPage.organization.avito_link,
				},
				page: {
					name: newPage.name,
					status: newPage.status,
					// Дополнительные поля страницы
				},
			}

			axios
				.post("/api/pages/create_page/", data, {
					headers: {
						"X-CSRFToken": getCSRFToken(),
					},
				})
				.then(response => {
					const newPageId = response.data.id // Получаем ID новой страницы
					const transformedData = {
						...transformDataForState(data),
						id: newPageId, // Установка нового ID для строки
					}
					setRows(prevRows => [transformedData, ...prevRows])
					setNewPage({
						organization: {
							user: {
								name: "",
								user_id: "",
								referrer_id: "",
								phone_number: "",
								subscription: formatDate(addDays(new Date(), 7)),
							},
							trial_period: true,
							subscription_renewed: false,
							name: "",
							recording: "",
							site_link: "",
							yandex_maps_link: "",
							google_maps_link: "",
							twogis_link: "",
							vk_link: "",
							prodoctorov_link: "",
							ireccomend_link: "",
							zoon_link: "",
							otzovik_link: "",
							yell_link: "",
							tripadvisor_link: "",
							whatsapp_link: "",
							avito_link: "",
							logo: null,
						},
						page: "",
						status: 1,
					})
					handleCloseAddDialog()
				})
				.catch(error => {
					setOpenErrorSnackbar(true)
					console.error("Axios error:", error)
				})
		} else {
			setOpenErrorSnackbar(true)
			console.log("Ошибка валидации")
		}
	}

	const convertDateToISO = dateStr => {
		const parts = dateStr.split(".")
		if (parts.length === 3) {
			return `${parts[2]}-${parts[1]}-${parts[0]}`
		}
		return dateStr // Возвращаем исходную строку, если формат не соответствует ожидаемому
	}

	// Функция для валидации заполнения полей
	const validatePage = page => {
		const userFilled = page.organization.user.name !== "" && page.organization.user.user_id !== "" && page.organization.user.phone_number !== "" && page.organization.user.subscription !== ""

		const organizationFilled = page.organization.name !== ""

		const linksFilled = page.organization.yandex_maps_link !== "" || page.organization.google_maps_link !== "" || page.organization.twogis_link !== "" || page.organization.vk_link !== "" || page.organization.prodoctorov_link !== "" || page.organization.ireccomend_link !== "" || page.organization.zoon_link !== "" || page.organization.otzovik_link !== "" || page.organization.yell_link !== "" || page.organization.tripadvisor_link !== "" || page.organization.whatsapp_link !== "" || page.organization.avito_link !== "" || page.organization.recording !== "" || page.organization.site_link !== ""

		const pageNameFilled = page.name !== "" && page.status !== ""

		const logoUploaded = page.organization.logo !== null // Проверка загрузки логотипа

		// console.log("UF: ", userFilled, "OF: ", organizationFilled, "LF: ", linksFilled, "PNF: ", pageNameFilled, "LU: ", logoUploaded)

		return userFilled && organizationFilled && linksFilled && pageNameFilled && logoUploaded
	}

	const initialPageData = {
		organization: {
			user: {
				name: "",
				user_id: "",
				referrer_id: "",
				phone_number: "",
				subscription: new Date().toISOString(), // Используйте ISO строку для даты
			},
			trial_period: true,
			subscription_renewed: false,
			name: "",
			recording: "",
			site_link: "",
			yandex_maps_link: "",
			google_maps_link: "",
			twogis_link: "",
			vk_link: "",
			prodoctorov_link: "",
			ireccomend_link: "",
			zoon_link: "",
			otzovik_link: "",
			yell_link: "",
			tripadvisor_link: "",
			whatsapp_link: "",
			avito_link: "",
			logo: null,
		},
		name: "",
		status: 1, // или другое подходящее начальное значение
	}

	const [openInfoModal, setOpenInfoModal] = useState(false)
	const [editMode, setEditMode] = useState(false)
	const [infoModalData, setInfoModalData] = useState(initialPageData)

	const [isLogoModalOpen, setIsLogoModalOpen] = useState(false)
	const [isQRModalOpen, setIsQRModalOpen] = useState(false)

	const handleOpenLogoModal = () => {
		setIsLogoModalOpen(true)
	}

	const handleCloseLogoModal = () => {
		setIsLogoModalOpen(false)
	}

	const handleOpenQRModal = () => {
		setIsQRModalOpen(true)
	}

	const handleCloseQRModal = () => {
		setIsQRModalOpen(false)
	}

	const [editedPage, setEditedPage] = useState(initialPageData) // Состояние для редактирования данных

	// Функция для открытия модального окна и установки данных для редактирования
	const handleOpenInfoModal = row => {
		setInfoModalData(row) // Устанавливаем данные выбранной записи
		setEditedPage({ ...row }) // Создаем копию данных для редактирования
		setOpenInfoModal(true) // Открываем модальное окно
	}

	const handleToggleEditMode = () => {
		setEditMode(prevEditMode => !prevEditMode)
	}

	const handleCancelChanges = () => {
		// Отменяем изменения и переключаемся из режима редактирования
		setEditedPage(infoModalData)
		setIsEditedLogoUploaded(false)
		setEditMode(false)
	}

	const handleSaveChanges = () => {
		if (editedPage != null) {
			// Подготовка данных для отправки
			const dataToUpdate = {
				id: editedPage.id, // ID страницы для обновления
				page: {
					name: editedPage.name,
					status: editedPage.status,
					// Дополнительные поля, специфичные для модели Pages, если они есть
				},
				organization: {
					trial_period: editedPage.organization.trial_period,
					subscription_renewed: editedPage.organization.subscription_renewed,
					id: editedPage.organization.id,
					name: editedPage.organization.name,
					recording: editedPage.organization.recording,
					site_link: editedPage.organization.site_link,
					logo: isEditedLogoUploaded ? editedPage.organization.logo : null,
					yandex_maps_link: editedPage.organization.yandex_maps_link,
					google_maps_link: editedPage.organization.google_maps_link,
					twogis_link: editedPage.organization.twogis_link,
					vk_link: editedPage.organization.vk_link,
					prodoctorov_link: editedPage.organization.prodoctorov_link,
					ireccomend_link: editedPage.organization.ireccomend_link,
					zoon_link: editedPage.organization.zoon_link,
					otzovik_link: editedPage.organization.otzovik_link,
					yell_link: editedPage.organization.yell_link,
					tripadvisor_link: editedPage.organization.tripadvisor_link,
					whatsapp_link: editedPage.organization.whatsapp_link,
					avito_link: editedPage.organization.avito_link,
					// Дополнительные поля, специфичные для модели Organization, если они есть
				},
				user: {
					id: editedPage.organization.user.id,
					user_id: editedPage.organization.user.user_id,
					referrer_id: editedPage.organization.user.referrer_id,
					name: editedPage.organization.user.name,
					phone_number: editedPage.organization.user.phone_number,
					subscription: convertDateToISO(editedPage.organization.user.subscription),
					// Дополнительные поля, специфичные для модели User, если они есть
				},
			}

			// Отправка запроса на обновление
			axios
				.post("/api/pages/update_page/", dataToUpdate, {
					headers: {
						"X-CSRFToken": getCSRFToken(),
					},
				})
				.then(response => {
					// Обновление данных в таблице после успешного ответа сервера
					setRows(prevRows => prevRows.map(row => (row.id === editedPage.id ? editedPage : row)))
					handleToggleEditMode()
					setInfoModalData(editedPage)
					setIsEditedLogoUploaded(false)
				})
				.catch(error => {
					console.error("Ошибка при обновлении:", error)
					setOpenErrorSnackbar(true)
				})
		}
	}

	const handleCloseInfoModal = () => {
		setOpenInfoModal(false)
		setEditMode(false)
		setIsEditedLogoUploaded(false)
	}

	const handleOpenDeleteDialog = row => {
		setDeletingRow(row)
		setOpenDeleteDialog(true)
	}

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false)
		setDeletingRow(null)
	}

	const handleDelete = () => {
		if (deletingRow != null) {
			axios
				.post(
					`/api/pages/delete_page/`,
					{ id: deletingRow.id },
					{
						headers: {
							"X-CSRFToken": getCSRFToken(),
						},
					}
				)
				.then(response => {
					// Удаление строки из состояния после успешного ответа сервера
					setRows(rows.filter(row => row.id !== deletingRow.id))
					handleCloseDeleteDialog()
				})
				.catch(error => {
					console.error("Ошибка при удалении:", error)
					setOpenErrorSnackbar(true)
				})
		}
	}

	const toggleStatus = row => {
		axios
			.post(
				`/api/pages/toggle_status/`,
				{ id: row.id },
				{
					headers: {
						"X-CSRFToken": getCSRFToken(),
					},
				}
			)
			.then(response => {
				setRows(
					rows.map(r => {
						if (r === row) {
							return { ...r, status: r.status === 0 ? 1 : 0 }
						}
						return r
					})
				)
			})
			.catch(error => {
				console.error("Ошибка при изменении статуса:", error)
				setOpenErrorSnackbar(true)
			})
	}

	const handleLogout = async () => {
		axios
			.post(
				"/api/custom_users/logout/",
				{},
				{
					headers: {
						"X-CSRFToken": getCSRFToken(),
					},
				}
			)
			.then(response => {
				navigate("/admin-login")
			})
			.catch(error => {
				setOpenErrorSnackbar(true)
				console.error("Axios error:", error)
			})
	}

	const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false)

	const handleCloseErrorSnackbar = () => {
		setOpenErrorSnackbar(false)
	}

	function getComparatorNumberOrString(order, orderBy) {
		return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
	}

	const isSelected = name => selected.indexOf(name) !== -1
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
	const visibleRows = React.useMemo(
		() =>
			stableSort(
				rows.filter(el => el.organization.user.user_id.toLowerCase().includes(filterText.toLowerCase()) || el.organization.user.name.toLowerCase().includes(filterText.toLowerCase()) || el.organization.user.phone_number.toLowerCase().includes(filterText.toLowerCase()) || el.organization.name.toLowerCase().includes(filterText.toLowerCase()) || el.name.toLowerCase().includes(filterText.toLowerCase())),
				getComparatorNumberOrString(order, orderBy)
			).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
		[order, orderBy, page, rowsPerPage, rows, filterText]
	)

	return (
		<ThemeProvider theme={theme}>
			<div className="admin-dashboard">
				<AppBar position="static">
					<Toolbar>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							OTZO.PRO | Административная панель
						</Typography>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="linkMobile">
							<Link to="/admin-statistics/" style={{ color: "#fff" }}>
								Статистика
							</Link>
						</Typography>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="linkMobile">
							<Link to="/admin-change-password/" style={{ color: "#fff" }}>
								Сменить пароль
							</Link>
						</Typography>
						<IconButton color="inherit" onClick={handleLogout}>
							<ExitToAppIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Box sx={{ maxWidth: "90%", margin: "20px auto" }}>
					<div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
						<Button variant="contained" color="primary" onClick={handleOpenAddDialog}>
							Добавить страницу
						</Button>
						<form onSubmit={handleSubmit(onSearch)} className="form" sx={{ maxWidth: "100%" }}>
							<div style={{ maxWidth: "37%" }}>
								<TextField type="search" sx={{ width: "300px", maxWidth: "100%" }} size="small" id="outlined-basic" label="Поиск" variant="outlined" {...register("search")} />
							</div>
							<Button variant="contained" type="submit">
								Найти
							</Button>
							<Button
								variant="outlined"
								onClick={() => {
									setFilterText("")
									reset()
								}}
							>
								Отмена
							</Button>
						</form>
					</div>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />
							<TableBody>
								{visibleRows.map(row => {
									const isItemSelected = isSelected(row.id)
									return (
										<TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected} sx={{ cursor: "pointer" }}>
											<TableCell sx={{ whiteSpace: "nowrap" }} component="th" scope="row">
												{row.organization.user.name}
											</TableCell>
											<TableCell sx={{ whiteSpace: "nowrap" }}>{row.organization.user.user_id}</TableCell>
											<TableCell sx={{ whiteSpace: "nowrap" }}>
												<a href={`tel:${row.phone}`}>{row.organization.user.phone_number}</a>
											</TableCell>
											<TableCell sx={{ whiteSpace: "nowrap" }}>{row.organization.user.subscription}</TableCell>
											<TableCell sx={{ whiteSpace: "nowrap" }}>{row.organization.name}</TableCell>
											<TableCell sx={{ whiteSpace: "nowrap" }}>
												<Link target="_blank" to={`/${row.name}`}>
													{row.name}
												</Link>
											</TableCell>
											<TableCell sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}>
												<IconButton aria-label="delete" onClick={() => handleOpenDeleteDialog(row)}>
													<DeleteIcon />
												</IconButton>
												<IconButton aria-label="toggle" onClick={() => toggleStatus(row)}>
													{row.status === 1 ? <ToggleOnIcon style={{ color: "#4caf50" }} /> : <ToggleOffIcon style={{ color: "#e57373" }} />}
												</IconButton>
												<IconButton aria-label="info" onClick={() => handleOpenInfoModal(row)}>
													<InfoIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination showFirstButton={true} showLastButton={true} labelRowsPerPage="КОЛ-ВО ЗАПИСЕЙ НА СТРАНИЦЕ" rowsPerPageOptions={[10, 30, 50, 100, rows.length]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
				</Box>
				{/* Модальное окно */}
				<Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{"Подтвердите удаление"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">Вы уверены, что хотите удалить эту страницу?</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDeleteDialog}>Отмена</Button>
						<Button onClick={handleDelete} autoFocus>
							Удалить
						</Button>
					</DialogActions>
				</Dialog>
				{/* Модальное окно для добавления страницы */}
				<Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
					<DialogTitle>Добавить новую страницу</DialogTitle>
					<DialogContent>
						<TextField autoFocus margin="dense" id="name" label="Клиент" type="text" fullWidth variant="outlined" value={newPage.organization.user.name} onChange={handleNewPageChange("organization.user.name")} />
						<TextField margin="dense" id="telegramId" label="Telegram ID пользователя" type="text" fullWidth variant="outlined" value={newPage.organization.user.user_id} onChange={handleNewPageChange("organization.user.user_id")} />
						<TextField margin="dense" id="referrerTelegramId" label="Telegram ID реферера" type="text" fullWidth variant="outlined" value={newPage.organization.user.referrer_id} onChange={handleNewPageChange("organization.user.referrer_id")} />
						<TextField margin="dense" id="phone" label="Номер телефона" type="tel" fullWidth variant="outlined" value={newPage.organization.user.phone_number} onChange={handleNewPageChange("organization.user.phone_number")} />
						<FormControlLabel control={<Checkbox defaultChecked />} label="Тестовый период" value={newPage.organization.trial_period} onChange={handleNewPageChange("organization.trial_period")} />
						<FormControlLabel control={<Checkbox defaultChecked />} label="Подписка продлена" value={newPage.organization.subscription_renewed} onChange={handleNewPageChange("organization.subscription_renewed")} />
						<TextField
							margin="dense"
							id="subscription"
							label="Подписка"
							type="date"
							fullWidth
							variant="outlined"
							value={formatDate(selectedDate)} // Используем selectedDate
							onChange={event => handleSubscriptionChange(new Date(event.target.value))} // Преобразуем строку в объект Date и вызываем функцию handleSubscriptionChange
							InputProps={{
								inputProps: {
									min: formatDate(initialDate), // Устанавливаем минимальное значение
								},
							}}
						/>
						<TextField margin="dense" id="organization" label="Организация (в Род. Падеже)" type="text" fullWidth variant="outlined" value={newPage.organization.name} onChange={handleNewPageChange("organization.name")} />
						<input
							accept="image/*" // Указывает, что принимаются только изображения
							style={{ display: "none" }} // Скрывает input
							id="raised-button-file"
							type="file"
							onChange={handleLogoChange}
						/>
						<label htmlFor="raised-button-file">
							<Button variant="contained" component="span" fullWidth style={{ backgroundColor: isLogoUploaded ? "green" : undefined }}>
								{isLogoUploaded ? "Логотип загружен" : "Загрузить логотип"}
							</Button>
						</label>
						{isLogoUploaded && newPage.organization.logo && (
							<div style={{ marginTop: "10px", textAlign: "center" }}>
								<Button fullWidth onClick={handleOpenLogoModal}>
									Посмотреть логотип
								</Button>
							</div>
						)}
						<TextField margin="dense" id="page" label="Страница" type="text" fullWidth variant="outlined" value={newPage.name} onChange={handleNewPageChange("name")} />
						<TextField margin="dense" id="page" label="Онлайн-запись" type="text" fullWidth variant="outlined" value={newPage.organization.recording} onChange={handleNewPageChange("organization.recording")} />
						<TextField margin="dense" id="page" label="Сайт" type="text" fullWidth variant="outlined" value={newPage.organization.site_link} onChange={handleNewPageChange("organization.site_link")} />
						<TextField margin="dense" id="yandexLink" label="Ссылка на Яндекс Карты" type="url" fullWidth variant="outlined" value={newPage.organization.yandex_maps_link} onChange={handleNewPageChange("organization.yandex_maps_link")} />
						<TextField margin="dense" id="googleLink" label="Ссылка на Google" type="url" fullWidth variant="outlined" value={newPage.organization.google_maps_link} onChange={handleNewPageChange("organization.google_maps_link")} />
						<TextField margin="dense" id="twoGisLink" label="Ссылка на 2GIS" type="url" fullWidth variant="outlined" value={newPage.organization.twogis_link} onChange={handleNewPageChange("organization.twogis_link")} />
						<TextField margin="dense" id="vkLink" label="Ссылка на VK" type="url" fullWidth variant="outlined" value={newPage.organization.vk_link} onChange={handleNewPageChange("organization.vk_link")} />
						<TextField margin="dense" id="prodoctorovLink" label="Ссылка на Prodoctorov" type="url" fullWidth variant="outlined" value={newPage.organization.prodoctorov_link} onChange={handleNewPageChange("organization.prodoctorov_link")} />
						<TextField margin="dense" id="iReccomendLink" label="Ссылка на iReccomend" type="url" fullWidth variant="outlined" value={newPage.organization.ireccomend_link} onChange={handleNewPageChange("organization.ireccomend_link")} />
						<TextField margin="dense" id="zoonLink" label="Ссылка на Zoon" type="url" fullWidth variant="outlined" value={newPage.organization.zoon_link} onChange={handleNewPageChange("organization.zoon_link")} />
						<TextField margin="dense" id="otzovikLink" label="Ссылка на Otzovik" type="url" fullWidth variant="outlined" value={newPage.organization.otzovik_link} onChange={handleNewPageChange("organization.otzovik_link")} />
						<TextField margin="dense" id="yellLink" label="Ссылка на Yell" type="url" fullWidth variant="outlined" value={newPage.organization.yell_link} onChange={handleNewPageChange("organization.yell_link")} />
						<TextField margin="dense" id="tripadvisorLink" label="Ссылка на Tripadvisor" type="url" fullWidth variant="outlined" value={newPage.organization.tripadvisor_link} onChange={handleNewPageChange("organization.tripadvisor_link")} />
						<TextField margin="dense" id="whatsAppLink" label="Ссылка на Whats App" type="url" fullWidth variant="outlined" value={newPage.organization.whatsapp_link} onChange={handleNewPageChange("organization.whatsapp_link")} />
						<TextField margin="dense" id="avitoLink" label="Ссылка на Avito" type="url" fullWidth variant="outlined" value={newPage.organization.avito_link} onChange={handleNewPageChange("organization.avito_link")} />
						<Dialog open={isLogoModalOpen} onClose={handleCloseLogoModal}>
							<img src={newPage.organization.logo} alt="Логотип" style={{ width: "100%", height: "auto", display: "block", marginLeft: "auto", marginRight: "auto" }} />
						</Dialog>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseAddDialog} color="primary">
							Отменить
						</Button>
						<Button onClick={handleAddPage} color="primary">
							Добавить
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={openInfoModal} onClose={handleCloseInfoModal}>
					<DialogTitle>Информация о странице</DialogTitle>
					<DialogContent>
						{editMode ? (
							<div>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Клиент"
									value={editedPage.organization.user.name}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												user: {
													...editedPage.organization.user,
													name: e.target.value,
												},
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Telegram ID"
									value={editedPage.organization.user.user_id}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												user: {
													...editedPage.organization.user,
													user_id: e.target.value,
												},
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Telegram ID реферера"
									value={editedPage.organization.user.referrer_id}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												user: {
													...editedPage.organization.user,
													referrer_id: e.target.value,
												},
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Номер телефона"
									value={editedPage.organization.user.phone_number}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												user: {
													...editedPage.organization.user,
													phone_number: e.target.value,
												},
											},
										})
									}
								/>
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label="Тестовый период"
									value={editedPage.organization.trial_period}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												trial_period: e.target.value,
											},
										})
									}
								/>
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label="Подписка продлена"
									value={editedPage.organization.subscription_renewed}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												subscription_renewed: e.target.value,
											},
										})
									}
								/>

								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Подписка"
									type="date"
									value={convertDateToISO(editedPage.organization.user.subscription)}
									onChange={e => {
										const newDate = new Date(e.target.value)
										const formattedDate = [newDate.getDate().toString().padStart(2, "0"), (newDate.getMonth() + 1).toString().padStart(2, "0"), newDate.getFullYear()].join(".")

										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												user: {
													...editedPage.organization.user,
													subscription: formattedDate,
												},
											},
										})
									}}
									InputProps={{
										inputProps: {
											min: convertDateToISO(formatDate(initialDate)), // Преобразовываем начальную дату
										},
									}}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Организация (в Род. Падеже)"
									value={editedPage.organization.name}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												name: e.target.value,
											},
										})
									}
								/>
								<input
									accept="image/*" // Указывает, что принимаются только изображения
									style={{ display: "none" }} // Скрывает input
									id="raised-button-file"
									type="file"
									onChange={handleEditedLogoChange}
								/>
								<label htmlFor="raised-button-file">
									<Button variant="contained" component="span" fullWidth style={{ backgroundColor: isEditedLogoUploaded ? "green" : undefined }}>
										{isEditedLogoUploaded ? "Новый логотип загружен" : "Загрузить новый логотип"}
									</Button>
								</label>
								{/* {isLogoUploaded && newPage.organization.logo && (
                                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                                        <Button fullWidth onClick={handleOpenLogoModal}>Посмотреть логотип</Button>
                                    </div>
                                )} */}
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Страница"
									value={editedPage.name}
									onChange={e =>
										setEditedPage({
											...editedPage,
											name: e.target.value,
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Онлайн-запись"
									value={editedPage.organization.recording}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												recording: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Сайт"
									value={editedPage.organization.site_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												site_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на Яндекс Карты"
									value={editedPage.organization.yandex_maps_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												yandex_maps_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на Google Карты"
									value={editedPage.organization.google_maps_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												google_maps_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на 2GIS"
									value={editedPage.organization.twogis_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												twogis_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на VK"
									value={editedPage.organization.vk_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												vk_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на Prodoctorov"
									value={editedPage.organization.prodoctorov_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												prodoctorov_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на iReccomed"
									value={editedPage.organization.ireccomend_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												ireccomend_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на Zoon"
									value={editedPage.organization.zoon_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												zoon_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на Otzovik"
									value={editedPage.organization.otzovik_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												otzovik_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на Yell"
									value={editedPage.organization.yell_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												yell_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на TripAdvisor"
									value={editedPage.organization.tripadvisor_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												tripadvisor_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на Whats App"
									value={editedPage.organization.whatsapp_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												whatsapp_link: e.target.value,
											},
										})
									}
								/>
								<TextField
									margin="dense"
									fullWidth
									variant="outlined"
									label="Ссылка на Avito"
									value={editedPage.organization.avito_link}
									onChange={e =>
										setEditedPage({
											...editedPage,
											organization: {
												...editedPage.organization,
												avito_link: e.target.value,
											},
										})
									}
								/>
							</div>
						) : (
							<div>
								<Typography variant="body1">Клиент: {infoModalData.organization.user.name}</Typography>
								<Typography variant="body1">Telegram ID: {infoModalData.organization.user.user_id}</Typography>
								{infoModalData.organization.user.referrer_id && <Typography variant="vody1">Telegram ID реферера: {infoModalData.organization.user.referrer_id}</Typography>}
								<Typography variant="body1">
									Номер телефона: <a href={`tel:${infoModalData.organization.user.phone_number}`}>{infoModalData.organization.user.phone_number}</a>
								</Typography>
								<FormControlLabel control={<Checkbox defaultChecked />} label="Тестовый период" value={infoModalData.organization.trial_period} />
								<FormControlLabel control={<Checkbox defaultChecked />} label="Подписка продлена" value={infoModalData.organization.subscription_renewed} />
								<Typography variant="body1">Подписка: {infoModalData.organization.user.subscription}</Typography>
								<Typography variant="body1">Организация: {infoModalData.organization.name}</Typography>
								<Typography variant="body1">
									<a href={"/" + infoModalData.name} target="_blank" rel="noopener noreferrer">
										Страница
									</a>
								</Typography>
								{infoModalData.organization.recording && (
									<Typography variant="body1">
										<a href={infoModalData.organization.recording} target="_blank" rel="noopener noreferrer">
											Онлайн-запись
										</a>
									</Typography>
								)}
								{infoModalData.organization.site_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.site_link} target="_blank" rel="noopener noreferrer">
											Сайт
										</a>
									</Typography>
								)}
								{infoModalData.organization.yandex_maps_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.yandex_maps_link} target="_blank" rel="noopener noreferrer">
											Ссылка на Яндекс Карты
										</a>
									</Typography>
								)}
								{infoModalData.organization.google_maps_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.google_maps_link} target="_blank" rel="noopener noreferrer">
											Ссылка на Google Карты
										</a>
									</Typography>
								)}
								{infoModalData.organization.twogis_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.twogis_link} target="_blank" rel="noopener noreferrer">
											Ссылка на 2GIS
										</a>
									</Typography>
								)}
								{infoModalData.organization.vk_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.vk_link} target="_blank" rel="noopener noreferrer">
											Ссылка на VK
										</a>
									</Typography>
								)}
								{infoModalData.organization.prodoctorov_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.prodoctorov_link} target="_blank" rel="noopener noreferrer">
											Ссылка на Prodoctorov
										</a>
									</Typography>
								)}
								{infoModalData.organization.ireccomend_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.ireccomend_link} target="_blank" rel="noopener noreferrer">
											Ссылка на iReccomend
										</a>
									</Typography>
								)}
								{infoModalData.organization.zoon_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.zoon_link} target="_blank" rel="noopener noreferrer">
											Ссылка на Zoon
										</a>
									</Typography>
								)}
								{infoModalData.organization.otzovik_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.otzovik_link} target="_blank" rel="noopener noreferrer">
											Ссылка на Otzovik
										</a>
									</Typography>
								)}
								{infoModalData.organization.yell_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.yell_link} target="_blank" rel="noopener noreferrer">
											Ссылка на Yell
										</a>
									</Typography>
								)}
								{infoModalData.organization.tripadvisor_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.tripadvisor_link} target="_blank" rel="noopener noreferrer">
											Ссылка на TripAdvisor
										</a>
									</Typography>
								)}
								{infoModalData.organization.whatsapp_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.whatsapp_link} target="_blank" rel="noopener noreferrer">
											Ссылка на Whats App
										</a>
									</Typography>
								)}
								{infoModalData.organization.avito_link && (
									<Typography variant="body1">
										<a href={infoModalData.organization.avito_link} target="_blank" rel="noopener noreferrer">
											Ссылка на Avito
										</a>
									</Typography>
								)}
								<Box sx={{ marginTop: 2 }}>
									<Button onClick={handleOpenLogoModal}>Посмотреть логотип</Button>
									<Button onClick={handleOpenQRModal}>Посмотреть QR</Button>
								</Box>
								{/* Модальное окно для логотипа */}
								<Dialog open={isLogoModalOpen} onClose={handleCloseLogoModal}>
									{infoModalData.organization.logo && <img src={infoModalData.organization.logo.includes("data:image/") ? infoModalData.organization.logo : `data:image/jpeg;base64,${infoModalData.organization.logo}`} alt="Логотип" style={{ maxWidth: "100%", maxHeight: "400px", display: "block", marginLeft: "auto", marginRight: "auto" }} />}
								</Dialog>
								{/* Модальное окно для QR кода */}
								<Dialog open={isQRModalOpen} onClose={handleCloseQRModal}>
									<div style={{ padding: "20px" }}>
										<QRCode value={`https://otzo.pro/${infoModalData.name}`} size={256} />
									</div>
								</Dialog>
							</div>
						)}
					</DialogContent>
					<DialogActions>
						{editMode ? (
							<div>
								<Button onClick={handleCancelChanges} color="primary">
									Отменить
								</Button>
								<Button onClick={handleSaveChanges} color="primary">
									Сохранить
								</Button>
							</div>
						) : (
							<div>
								<Button onClick={handleCloseInfoModal} color="primary">
									Закрыть
								</Button>
								<Button onClick={handleToggleEditMode} color="primary">
									Редактировать
								</Button>
							</div>
						)}
					</DialogActions>
				</Dialog>
				<Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleCloseErrorSnackbar}>
					<Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: "100%" }}>
						Что-то пошло не так!
					</Alert>
				</Snackbar>
			</div>
		</ThemeProvider>
	)
}

export default AdminDashboardSecondPage
