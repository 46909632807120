import React from "react"
import { Link } from "react-router-dom"
import "./SuccessPage.css"

const SuccessPage = () => {
	return (
		<div className="not-found">
			<div className="text">
				<p>Ваше сообщение отправлено. Мы уже принимаем все меры для решения вопроса.</p>
				<Link className="link" to="/">
					Вернуться на главную
				</Link>
			</div>
		</div>
	)
}

export default SuccessPage
