import React, { useEffect, useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import axios from "axios"
import "./StatisticPage.css"
import { Box, AppBar, Toolbar, Typography, IconButton, Snackbar, Alert } from "@mui/material"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { ruRU } from "@mui/material/locale"

const StatisticPage = () => {
	const navigate = useNavigate()
	const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false)
	const [data, setData] = useState({
		total_pages_created: 0,
		pages_with_referral: 0,
		pages_without_referral: 0,
		pages_created_today: 0,

		pages_created_this_month: 0,
		pages_created_last_month: 0,
		pages_created_this_year: 0,
		pages_created_last_year: 0,

		users_with_inactive_trial: [],
		users_without_subscription_renewed: [],
		users_with_subscription_renewed: [],
		users_with_active_trial: [],

		users_subscriptions_end_this_month: 0,
		users_subscriptions_end_in_two_weeks: 0,
		users_subscriptions_end_in_one_day: 0,
	})

	const formatDate = date => {
		if (typeof date === "string") {
			// Если входная дата является строкой, попробуйте её конвертировать в объект Date
			date = new Date(date)
		}

		if (date instanceof Date && !isNaN(date)) {
			// Проверка, что date является действительным объектом Date
			const day = date.getDate()
			const month = date.getMonth() + 1 // Месяцы начинаются с 0
			const year = date.getFullYear()
			return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`
		} else {
			// Если не удалось конвертировать в Date или date недействителен, вернуть пустую строку или другое значение по умолчанию
			return "" // Или можно вернуть, например, 'Неверный формат даты'
		}
	}

	function getCSRFToken() {
		const csrfCookie = document.cookie.match(/csrftoken=([\w-]+)/)
		return csrfCookie ? csrfCookie[1] : null
	}

	useEffect(() => {
		axios
			.get(
				"/api/pages/get_statistics/",
				{},
				{
					headers: {
						"X-CSRFToken": getCSRFToken(),
					},
				}
			)
			.then(response => {
				setData(response.data.statistics)
			})
			.catch(error => {
				console.error("Axios error:", error)
			})
	}, [])

	const handleCloseErrorSnackbar = () => {
		setOpenErrorSnackbar(false)
	}

	const theme = createTheme(
		{
			palette: {
				primary: { main: "#1976D2" }, // Нежно-голубой для AppBar
				background: { default: "#f5f5f5", paper: "#fff" }, // Светло-серый для фона и белый для Paper
				text: { primary: "#333", secondary: "#555" }, // Темные оттенки для текста
			},
			// Дополнительные настройки темы могут быть добавлены здесь
		},
		ruRU
	)

	const handleLogout = async () => {
		axios
			.post(
				"/api/custom_users/logout/",
				{},
				{
					headers: {
						"X-CSRFToken": getCSRFToken(),
					},
				}
			)
			.then(response => {
				navigate("/admin-login")
			})
			.catch(error => {
				setOpenErrorSnackbar(true)
				console.error("Axios error:", error)
			})
	}

	return (
		<ThemeProvider theme={theme}>
			<div className="admin-dashboard">
				<AppBar position="static">
					<Toolbar>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							OTZO.PRO | Статистика
						</Typography>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							<Link to="/admin-dashboard/" style={{ color: "#fff" }}>
								Админ-панель
							</Link>
						</Typography>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							<Link to="/admin-change-password/" style={{ color: "#fff" }}>
								Сменить пароль
							</Link>
						</Typography>
						<IconButton color="inherit" onClick={handleLogout}>
							<ExitToAppIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Box sx={{ margin: "20px auto", padding: "10px 40px" }}>
					<div style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch", gap: "20px", width: "100%", justifyContent: "center" }}>
						<div className="stat-block" style={{ backgroundColor: "#16c769" }}>
							<p>
								Страниц создано <br /> всего
							</p>
							<h1>{data.total_pages_created}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#16c769" }}>
							<p>
								Страниц создано <br />
								по реф.ссылке
							</p>
							<h1>{data.pages_with_referral}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#16c769" }}>
							<p>
								Страниц создано <br />
								без реф.ссылки
							</p>
							<h1>{data.pages_without_referral}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#16c769" }}>
							<p>
								Страниц создано <br />
								сегодня
							</p>
							<h1>{data.pages_created_today}</h1>
						</div>
						{/*  */}
						<div className="stat-block" style={{ backgroundColor: "#5491BC" }}>
							<p>
								Страниц создано <br />в этом месяце
							</p>
							<h1>{data.pages_created_this_month}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#5491BC" }}>
							<p>
								Страниц создано <br />в предыдущем месяце
							</p>
							<h1>{data.pages_created_last_month}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#5491BC" }}>
							<p>
								Страниц создано <br />в этом году
							</p>
							<h1>{data.pages_created_this_year}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#5491BC" }}>
							<p>
								Страниц создано <br />в прошлом году
							</p>
							<h1>{data.pages_created_last_year}</h1>
						</div>
						{/*  */}

						<div className="stat-block" style={{ backgroundColor: "#A21C37" }} id="unsold-tests">
							<p>
								Тестовых периодов <br /> непродлённых
								<br />
								<span>* нажмите для подробностей</span>
							</p>
							<h1>{data.users_with_inactive_trial.length}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#A21C37" }} id="unsold-subs">
							<p>
								Подписки <br /> непродлённые
								<br />
								<span>* нажмите для подробностей</span>
							</p>
							<h1>{data.users_without_subscription_renewed.length}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#A21C37" }} id="sold-subs">
							<p>
								Подписки <br /> продлённые
								<br />
								<span>* нажмите для подробностей</span>
							</p>
							<h1>{data.users_with_subscription_renewed.length}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#A21C37" }} id="total-tests">
							<p>
								Тестовых периодов <br /> всего <br />
								<span>* нажмите для подробностей</span>
							</p>
							<h1>{data.users_with_active_trial.length}</h1>
						</div>
						{/*  */}
						<div className="stat-block" style={{ backgroundColor: "#ADB310" }}>
							<p>
								Окончание подписки <br />в этом месяце
							</p>
							<h1>{data.users_subscriptions_end_this_month}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#ADB310" }}>
							<p>
								Окончание подписки <br />
								менее 14 дней
							</p>
							<h1>{data.users_subscriptions_end_in_two_weeks}</h1>
						</div>
						<div className="stat-block" style={{ backgroundColor: "#ADB310" }}>
							<p>
								Окончание подписки <br />
								сегодня
							</p>
							<h1>{data.users_subscriptions_end_in_one_day}</h1>
						</div>
					</div>
					<div style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch", gap: "10px", width: "100%", justifyContent: "center", marginTop: "40px", flexDirection: "column" }}>
						<h1 style={{ marginBottom: 0 }}>Тестовые периоды всего</h1>
						{data.users_with_active_trial.length > 0 ? (
							data.users_with_active_trial.map(el => {
								return (
									<div className="row">
										<p>{el.name}</p>
										<p>{el.phone_number}</p>
										{/* <p>Рекламное агенство Shosh</p>
									<p>
										<a href="/shosh">shosh</a>
									</p> */}
										<p>{formatDate(el.subscription)}</p>
									</div>
								)
							})
						) : (
							<p style={{ textAlign: "center", margin: "0 auto" }}>Таких пользователей нет</p>
						)}
						<h1 style={{ marginBottom: 0 }}>Тестовые периоды непродлённые</h1>
						{data.users_with_inactive_trial.length > 0 ? (
							data.users_with_inactive_trial.map(el => {
								return (
									<div className="row">
										<p>{el.name}</p>
										<p>{el.phone_number}</p>
										{/* <p>Рекламное агенство Shosh</p>
									<p>
										<a href="/shosh">shosh</a>
									</p> */}
										<p>{formatDate(el.subscription)}</p>
									</div>
								)
							})
						) : (
							<p style={{ textAlign: "center", margin: "0 auto" }}>Таких пользователей нет</p>
						)}
						<h1 style={{ marginBottom: 0 }}>Подписки непродлённые</h1>
						{data.users_without_subscription_renewed.length > 0 ? (
							data.users_with_inactive_trial.map(el => {
								return (
									<div className="row">
										<p>{el.name}</p>
										<p>{el.phone_number}</p>
										{/* <p>Рекламное агенство Shosh</p>
									<p>
										<a href="/shosh">shosh</a>
									</p> */}
										<p>{formatDate(el.subscription)}</p>
									</div>
								)
							})
						) : (
							<p style={{ textAlign: "center", margin: "0 auto" }}>Таких пользователей нет</p>
						)}
						<h1 style={{ marginBottom: 0 }}>Подписки продлённые</h1>
						{data.users_with_subscription_renewed.length > 0 ? (
							data.users_with_inactive_trial.map(el => {
								return (
									<div className="row">
										<p>{el.name}</p>
										<p>{el.phone_number}</p>
										{/* <p>Рекламное агенство Shosh</p>
									<p>
										<a href="/shosh">shosh</a>
									</p> */}
										<p>{formatDate(el.subscription)}</p>
									</div>
								)
							})
						) : (
							<p style={{ textAlign: "center", margin: "0 auto" }}>Таких пользователей нет</p>
						)}
					</div>
				</Box>
			</div>
			<Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleCloseErrorSnackbar}>
				<Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: "100%" }}>
					Что-то пошло не так!
				</Alert>
			</Snackbar>
		</ThemeProvider>
	)
}

export default StatisticPage
